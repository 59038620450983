import { get } from 'vuex-pathify'

export default {
	computed: {
		navbarHeight: get('shop/navbarHeight'),
		cMaxHeight() {
			if (this.$ssr) return

			const height = window.innerHeight - this.navbarHeight
			return height - this.appbarMenuOffset
		},
	},
}
