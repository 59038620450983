export default {
	props: {
		closeOnScroll: Boolean,
	},
	methods: {
		checkVisibility() {
			const element = this.$refs[this.visibilityRef]

			const elementRect = element.getBoundingClientRect()
			const isVisible = this.compareFn(elementRect)

			if (isVisible) {
				this.onVisibleCb?.()
			} else {
				this?.onNotVisibleCb?.()
			}
		},
	},
	mounted() {
		if (this.closeOnScroll) window.addEventListener('scroll', this.checkVisibility)
	},
	destroyed() {
		if (this.closeOnScroll) window.removeEventListener('scroll', this.checkVisibility)
	},
}
