<script>
const exceptions = ['a', 'con', 'de', 'en', 'para', 'por', 'y', 'o', 'ni', 'la']

export default {
	props: {
		selectedItem: Array,
	},
	filters: {
		lowerCase(value) {
			return value
				.split(' ')
				.map((w) => (exceptions.includes(w.toLowerCase()) ? w.toLowerCase() : w))
				.join(' ')
		},
	},
	computed: {
		lastSelectedItem() {
			return this.selectedItem[this.selectedItem.length - 1]
		},
	},
}
</script>

<template>
	<div>
		<div class="sublist-header text-center py-4 base" v-ripple @click="$emit('pop')">
			<v-icon style="position: absolute; left: 10px; height: auto">mdi-chevron-left</v-icon>
			{{ lastSelectedItem.name || lastSelectedItem.linkCat.name | lowerCase }}
		</div>

		<div v-for="(current, n) in lastSelectedItem.children || lastSelectedItem.categories" :key="n">
			<v-list-item
				v-if="current.children.some((c) => c.children.length)"
				class="base w100"
				@click="$emit('push', current)"
			>
				<v-list-item-title :color="$vars.basetext" class="base font-1">{{
					current.name | lowerCase
				}}</v-list-item-title>
			</v-list-item>

			<div v-else>
				<v-list-group v-if="current.children.length" append-icon="mdi-chevron-down" class="base">
					<template v-slot:activator>
						<v-list-item-title :color="$vars.basetext" class="base font-1">
							{{ current.name | lowerCase }}
						</v-list-item-title>
					</template>

					<SafeLink
						is="v-list-item"
						class="px-5 base"
						v-for="(subChildren, index) of current.children"
						:key="index"
						:to="$shop.getShopRoute({ categories: subChildren.familyUrlNames })"
					>
						<v-list-item-title> - {{ subChildren.name | lowerCase }} </v-list-item-title>
					</SafeLink>
				</v-list-group>

				<SafeLink
					v-else
					is="v-list-item"
					class="base"
					:to="$shop.getShopRoute({ categories: current.familyUrlNames })"
				>
					<v-list-item-title>{{ current.name | lowerCase }} </v-list-item-title>
				</SafeLink>
			</div>
		</div>
		<SafeLink
			v-if="lastSelectedItem.familyUrlNames && lastSelectedItem.pos === 1"
			:to="$shop.getShopRoute({ categories: lastSelectedItem.familyUrlNames })"
		>
			<v-list-item>
				<v-list-item-title class="base">
					Ver todo en {{ lastSelectedItem.name | lowerCase }}
				</v-list-item-title>
			</v-list-item>
		</SafeLink>
	</div>
</template>

<style></style>
