<template>
	<div class="category-title pt-2 pb-4 mb-4">
		<SafeLink :to="link" class="link">
			<span v-if="title" class="ml-4 is-heading font-4">{{ title }}</span>
			<span class="font-1 font-weight-bold ml-4">
				{{ subtitle }}
				<v-icon v-if="showIcon" small class="pb-1">mdi-chevron-right</v-icon>
			</span>
		</SafeLink>
	</div>
</template>

<script>
export default {
	props: {
		item: Object,
		showIcon: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		title() {
			return (
				this.item.customTitle ||
				(!this.item.selectType || this.item.selectType == 'category'
					? this.item.category?.name
					: this.item.selectType == 'brand'
					? this.item.brand.name
					: this.item.name)
			)
		},
		link() {
			if (this.item.customTitle?.length && this.item.customTitleUrl) {
				return this.item.customTitleUrl
			}
			if (this.selectType === 'brand' || this.selectType === 'url') {
				return ''
			}
			return this.$shop.getShopRoute({ categories: this.item.category?.familyUrlNames })
		},
		subtitle() {
			return this.item?.customSubtitle || 'Ver todo'
		},
	},
}
</script>

<style lang="scss" scoped></style>
