<script>
import { sync, get } from 'vuex-pathify'

export default {
	computed: {
		loading: get('shop/routeLoading'),
		appBarRoundDrawer: sync('shop/appBarRoundDrawer'),
	},
	cssVars: {
		selector: '.mobile-menu-drawer',
	},
	watch: {
		loading() {
			this.appBarRoundDrawer = false
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		class="mobile-menu-drawer base pa-4"
		v-model="appBarRoundDrawer"
		fixed
		width="600"
		max-width="100%"
		overlay-color="#000"
		temporary
		:right="$vuetify.breakpoint.xs"
		:overlay-opacity="0.8"
		touchless
	>
		<div class="close-button d-flex justify-space-between pa-3 align-center">
			<div class="font-weight-bold font-1">Categorías</div>
			<Button :ripple="false" icon @click="appBarRoundDrawer = false" fab small color="base">
				<v-icon large :color="$vars.basetext">mdi-close</v-icon>
			</Button>
		</div>
		<MobileMenuDrawer-MegaMenuCategories />
	</v-navigation-drawer>
</template>

<style></style>
