var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.megaMenuCategories.length)?_c('div',{ref:"megaMenuHorizontal",staticClass:"appbar__mega-menu d-flex",style:(_vm.categoriesStyle)},_vm._l((_vm.megaMenuCategories),function(item,index){return _c('div',{key:index,class:[_vm.$bem('mega-menu'), 'px-0', 'd-flex'],attrs:{"min-width":"200px"}},[_c('Hover',{attrs:{"unhover-on-route-loading":"","open-delay":_vm.$b.d ? 250 : 0,"close-delay":_vm.$b.d ? 250 : 0,"value":_vm.globalHover},on:{"updateModel":function($event){return _vm.updateHover($event, index)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var hover = ref.hover;
var toggle = ref.toggle;return [_c('div',[_c('SafeLink',{staticClass:"d-inline-block pt-3"},[_c('v-btn',{staticClass:"h100",class:[
							_vm.$bem('__underline'),
							( _obj = {}, _obj[_vm.$bem('__underline--permanent')] = hover || _vm.forceHover == index, _obj ) ],attrs:{"color":_vm.$vars.basetext,"text":"","depressed":"","small":""},on:{"click":function($event){return _vm.doToggle(hover, toggle)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.selectType == 'brand' ? item.brand.name : item.selectType == 'url' ? item.name : item.category.name)+" ")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.close && (hover || _vm.forceHover === index)),expression:"!close && (hover || forceHover === index)"}],staticClass:"px-6 py-1 pb-6 rounded",class:[_vm.$bem('__submenu'), _vm.submenuClass],style:([
						_vm.bgImageStyle(item),
						{ 'max-height': (_vm.cMaxHeight + "px!important") },
						_vm.widthStyle,
						_vm.submenuStyle ])},[_c('div',{class:_vm.$bem('__title-wrapper')},[(!item.disableTitle)?_c('CategoriesMegaMenu-CategoryTitle',{attrs:{"item":item},nativeOn:{"click":function($event){return toggle.apply(null, arguments)}}}):_vm._e()],1),_c('v-row',{staticClass:"pl-4 pt-0",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"pt-0",attrs:{"id":"menu"}},[_vm._l((item.ordered),function(ordered){return _c('div',{key:ordered.posId,staticClass:"category mb-4 pr-2",class:_vm.$bem('__children')},[(ordered.type == 'link')?_c('div',[(ordered.selectType == 'category')?_c('SafeLink',{staticClass:"font-1 font-weight-bold pb-1 link",attrs:{"to":_vm.$shop.getShopRoute({
													categories: ordered.category.familyUrlNames,
												})}},[_c('div',{staticClass:"ellipsis-text"},[_vm._v(" "+_vm._s(ordered.category.name)+" ")])]):_vm._e(),(ordered.selectType == 'brand')?_c('SafeLink',{staticClass:"font-1 font-weight-bold pb-1 link",attrs:{"to":_vm.$shop.getShopRoute({ brand: ordered.brand.urlName })}},[_c('div',{staticClass:"ellipsis-text"},[_vm._v(_vm._s(ordered.brand.name))])]):_c('SafeLink',{staticClass:"font-1 font-weight-bold pb-1 link",attrs:{"to":ordered.link}},[_c('div',{staticClass:"ellipsis-text"},[_vm._v(_vm._s(ordered.name))])]),_c('div',{class:_vm.$bem('__subcategories')},_vm._l((ordered.children),function(child,n){return _c('div',{key:n},[(child.selectType == 'category')?_c('SafeLink',{staticClass:"font-weight-bold pb-1 link",attrs:{"to":_vm.$shop.getShopRoute({
															categories: child.category.familyUrlNames,
														})}},[_vm._v(" "+_vm._s(child.category.name)+" ")]):_vm._e(),(child.selectType == 'brand')?_c('SafeLink',{staticClass:"font-weight-bold pb-1 link",attrs:{"to":_vm.$shop.getShopRoute({
															brand: child.brand.urlName,
														})}},[_vm._v(" "+_vm._s(child.brand.name)+" ")]):_c('SafeLink',{staticClass:"font-weight-bold pb-1 link",attrs:{"to":child.link}},[_vm._v(" "+_vm._s(child.name)+" ")])],1)}),0)],1):_c('div',[_c('SafeLink',{staticClass:"font-1 font-weight-bold pb-1 link",attrs:{"to":_vm.$shop.getShopRoute({
													categories: ordered.familyUrlNames,
												})}},[_vm._v(" "+_vm._s(ordered.name)+" ")]),_c('div',{class:_vm.$bem('__subcategories')},_vm._l((_vm.subChild(ordered)),function(subChild,i){return _c('div',{key:i},[_c('SafeLink',{staticClass:"font-weight-bold pb-1 link",attrs:{"to":_vm.$shop.getShopRoute({ categories: subChild.familyUrlNames })}},[_vm._v(" "+_vm._s(subChild.name)+" ")])],1)}),0),(_vm.hasManySubChildren(ordered))?_c('SafeLink',{staticClass:"font-1 link",attrs:{"to":_vm.$shop.getShopRoute({ categories: ordered.familyUrlNames })}},[_c('span',[_vm._v("Ver más")])]):_vm._e()],1)])}),_c('v-col',[_c('SafeLink',{attrs:{"to":item.link}},[_c('div',{class:_vm.$bem('__image')})])],1)],2)]),_c('v-col',[_c('SafeLink',{attrs:{"to":item.link}},[_c('div',{class:_vm.$bem('__image')})])],1)],1)],1)],1)]}}],null,true)})],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }