<script>
import appbarMixin from '@/v-shop/mixins/appbarMixin'

export default {
	mixins: [appbarMixin],
	data() {
		return {
			showTopAppBar: this.$srv('AppBarConfig.showTopNavbar', false),
			message: this.$srv('appbarTopMessage.message', ''),
			icon: this.$srv('appbarTopMessage.icon', ''),
			links: this.$srv('appbarTopMessage.links', []),
		}
	},
}
</script>

<template>
	<div class="appbar-top-msg secondary pa-3" v-if="$b.d && showTopAppBar">
		<div class="appbar-top-msg__content d-flex justify-space-between align-center">
			<div>
				<v-icon class="mx-3 secondary"> {{ icon }}</v-icon>
				<span class="font-1 secondary">{{ message }}</span>
			</div>
			<div v-if="links.length" class="pr-2 d-flex justify-end align-center">
				<SafeLink
					class="pl-2 mr-2 secondary"
					v-for="(link, i) in links"
					:to="link.url"
					:key="i"
					style="border-left: 1px solid white"
				>
					<div v-if="!link.type || link.type === 'link'">
						<v-icon v-if="link.icon" :color="$vars.secondarytext">{{ link.icon }}</v-icon>
						<span v-if="link.text">{{ link.text }}</span>
					</div>
					<Button v-else height="25px" :color="link.btnBgColor">
						<span :style="`color: ${link.btnTextColor}`">{{ link.text }}</span>
					</Button>
				</SafeLink>
			</div>
		</div>
	</div>
</template>

