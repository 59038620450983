<script>
export default {
	props: {
		transparent: Boolean,
	},
	cssVars: {
		selector: '.mobile-menu-drawer',
	},
}
</script>

<template>
	<div
		class="mobile-menu-drawer d-flex justify-center align-center w100 pa-2"
		ref="searchMobile"
		v-if="$b.m"
		:style="transparent && 'background: transparent!important'"
	>
		<div class="w100">
			<AutocompleteSearchField class="w100" />
		</div>
	</div>
</template>

<style lang="scss"></style>
