<template>
	<v-menu
		open-on-hover
		bottom
		offset-y
		class="px-0"
		min-width="200px"
		attach="#appbar-menu"
		v-if="categories.length"
	>
		<v-list :color="$vars.primary">
			<v-list-item
				v-for="(category, index) in categories"
				:key="index"
				class="primary"
				:to="$shop.getShopRoute({ categories: category.familyUrlNames })"
			>
				<v-list-item-title>
					{{ category.name }}
				</v-list-item-title>
			</v-list-item>
		</v-list>
		<template #activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" :color="$vars.primarytext" class="h100" large text depressed small>
				<span class="font-1">Categorías</span>
				<v-icon :color="$vars.primarytext">mdi-chevron-down</v-icon>
			</v-btn>
		</template>
	</v-menu>
</template>

<script>
export default {
	data() {
		return {
			categories: this.$srv('AppBarConfig.categories', []),
		}
	},
}
</script>

<style></style>
