<template>
	<div class="mw-300">
		<div class="image d-flex justify-center rounded">
			<SafeLink :to="link">
				<Media :src="img" class="image__media" width="170px">
					<div
						class="image__text font-2 mb-1 text-center link"
						style="background: transparent !important"
					>
						{{ text }}
					</div>
				</Media>
			</SafeLink>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		img: [String, Object],
		text: String,
		link: String,
	},
}
</script>

<style scoped lang="scss">
.mw-300 {
	max-width: 300px;
}

.image {
	width: 100%;

	&__media {
		position: relative;
	}

	&__text {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
</style>
