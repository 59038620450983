import { render, staticRenderFns } from "./comp_CategoriesMegaMenu-Overlay.vue?vue&type=template&id=624b17d6&scoped=true"
var script = {}
import style0 from "./comp_CategoriesMegaMenu-Overlay.vue?vue&type=style&index=0&id=624b17d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624b17d6",
  null
  
)

export default component.exports