<script>
export default {
	data() {
		return {
			megaMenuType: this.$srv('AppBarConfig.megaMenuType'),
			selectedItem: null,
		}
	},
	// mounted() {
	// 	this.setupCategoryLinkColor()
	// },
	computed: {
		megaMenuCategories() {
			return this.megaMenuType === 'vertical'
				? this.$srv('MegaMenu', [])
				: this.$srv('MegaMenuHorizontal', [])
		},
	},
	methods: {
		// setupCategoryLinkColor() {
		// 	const currentRoute = this.$route.path.split('/')[1]
		// 	if (!currentRoute) return

		// 	const index = this.megaMenuCategories.findIndex(
		// 		(c) => c.category.urlName.indexOf(currentRoute) !== -1
		// 	)
		// 	this.selectedItem = index
		// },
		getLink(item) {
			// if (!item.selectType || item.selectType == 'category') {
			// 	if (item.category?.children.length) {
			// 		console.log('category with no children')
			// 		return null
			// 	}
			// } else {
			// 	if (item.links?.length) {
			// 		console.log('item with no links')
			// 		return null
			// 	}
			// }

			if (item.selectType == 'url') return item.url
			else if (item.selectType == 'brand') return this.$shop.getShopRoute({ brand: item.brand.urlName })
			else return this.$shop.getShopRoute({ categories: item.category.familyUrlNames })
		},
		getAppendIcon(item) {
			if (this.itemHasSubitems(item)) return 'mdi-chevron-down'
			else return 'mdi-chevron-right'
		},
		// selectItem(i) {
		// 	this.selectedItem = i
		// },
		itemHasSubitems(item) {
			if (item.selectType == 'brand' || item.selectType == 'url') return !!item.links?.length
			else return !!item.category?.children
		},
	},
}
</script>

<template>
	<div>
		<v-list-item
			exact-active-class
			:ref="`listItem${i}`"
			v-for="(item, i) of megaMenuCategories"
			:key="i"
			append-icon="mdi-chevron-right"
			:to="getLink(item)"
		>
			<v-list-item-title :color="$vars.basetext" class="base font-1">
				<template v-if="item.selectType == 'brand'">
					{{ item.brand.name }}
				</template>
				<template v-else-if="item.selectType == 'url'">
					{{ item.urlText || item.name }}
				</template>
				<template v-else> {{ item.category.name }} </template>
			</v-list-item-title>
			<v-list-item-action>
				<v-icon :color="$vars.basetext" size="25">mdi-chevron-right</v-icon>
			</v-list-item-action>
		</v-list-item>
		<div class="border mx-4 my-2"></div>
	</div>
</template>

<style scoped>
.border {
	border: thin solid var(--primary);
}
</style>

<style></style>
