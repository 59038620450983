<script>
import appbarMixin from '@/v-shop/mixins/appbarMixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [appbarMixin, hooksMixin('AppBarButtons')],
	props: {
		dense: Boolean,
		cartIconColor: String,
		accountIconColor: String,
		favIconColor: String,
		noUserBtnText: {
			type: Boolean,
			default: false,
		},
		noWishlistBtnText: {
			type: Boolean,
			default: false,
		},
		noCartBtnText: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		purchaseMode() {
			return this.$srv('purchaseMode')
		},
		iconSize() {
			return this.dense ? 20 : 25
		},
		btnClass() {
			return this.dense ? 'px-1' : 'px-2'
		},
	},
}
</script>

<template>
	<div class="d-flex align-center justify-end">
		<hook zone="user">
			<v-btn
				v-if="user && $b.td"
				text
				:class="[btnClass, !noUserBtnText && 'mr-2']"
				:to="{ name: 'user.account' }"
				:color="accountIconColor || $vars.basetext"
			>
				<v-icon :size="iconSize" class="btn" :class="!noUserBtnText && 'mr-1'"> mdi-account</v-icon>
				<span
					v-if="!noUserBtnText"
					class="font-1"
					:style="`color: ${accountIconColor || $vars.basetext}`"
					>{{ user.firstname }}</span
				>
			</v-btn>
			<v-btn
				v-if="!user && $b.td"
				text
				:class="[btnClass, !noUserBtnText && 'mr-2']"
				@click="showLoginDrawer"
				:color="accountIconColor || $vars.basetext"
				:loading="loginLoading"
			>
				<v-icon :size="iconSize" :class="!noUserBtnText && 'mr-1'">mdi-account</v-icon>
				<span v-if="!noUserBtnText" class="font-1">Ingresar</span>
			</v-btn>
		</hook>
		<hook zone="wishlist">
			<v-btn
				v-if="$b.td"
				text
				:class="[btnClass, !noWishlistBtnText && 'mr-2']"
				@click="goToFavorites"
				:color="favIconColor || $vars.basetext"
			>
				<v-icon :size="iconSize" :class="!noWishlistBtnText && 'mr-1'">mdi-heart</v-icon>
				<span v-if="!noWishlistBtnText" class="d-none d-lg-block font-1">Favoritos</span>
			</v-btn>
		</hook>
		<hook zone="cart">
			<v-btn
				v-if="purchaseMode != 'single_product'"
				text
				@click="showCartDrawer"
				class="cart-btn mr-md-0 px-0 px-md-2"
				:color="cartIconColor || $vars.basetext"
				id="show-cart-drawer-btn"
			>
				<v-badge :content="orderItemsQty" :value="!!orderItemsQty" overlap aria-label="carrito">
					<v-icon :size="iconSize" :class="!noCartBtnText && 'mr-1'">{{
						$g('cartIcon', 'mdi-cart')
					}}</v-icon>
				</v-badge>
				<span v-if="!noCartBtnText" class="d-none d-lg-block font-1">Carrito</span>
			</v-btn>
		</hook>
	</div>
</template>

<style lang="scss" scoped>
.text-transform-none {
	text-transform: none;
}
</style>

