<script>
import { get, sync } from 'vuex-pathify'

export default {
	data() {
		return {
			brands: null,
			search: '',
		}
	},
	computed: {
		routeLoading: get('shop/routeLoading'),
		brandsDialog: sync('shop/brandsDialog'),
		filteredBrands() {
			let brands = this.brands
			if (this.search) {
				brands = brands.filter((x) =>
					x.name.toLowerCase().normalize('NFD').includes(this.search.toLowerCase().normalize('NFD'))
				)
			}
			return brands
		},
		height() {
			if (this.$ssr) return
			if (this.$b.m) return window.innerHeight - 150
			return window.innerHeight - 250
		},
	},
	watch: {
		brandsDialog(val) {
			if (!val) this.search = ''
			if (val && this.$b.d) {
				setTimeout(() => {
					this.$refs.searchfield.focus()
				}, 50)
			}
		},
		routeLoading(v) {
			if (v) this.brandsDialog = false
		},
	},
	created() {
		this.$shopApi.get({
			url: '/catalog/brands',
			query: { buyables: true },
			onSuccess: ({ data }) => {
				this.brands = data.brands
				this.brands.sort((a, b) => {
					const aName = a.name.toLowerCase()
					const bName = b.name.toLowerCase()
					if (aName < bName) return -1
					if (aName > bName) return 1
					return 0
				})
				if (this.$b.d) this.$refs.searchfield.focus()
			},
		})
	},
	cssVars: {
		selector: '.mobile-menu-drawer',
	},
}
</script>

<template>
	<v-dialog v-model="brandsDialog" :fullscreen="$b.m">
		<css-vars :default-vars="$vars" selector=".mobile-menu-drawer">
			<div class="mobile-menu-drawer base py-4 h100" style="position: relative">
				<div class="font-weight-bold font-2 mb-3 d-flex w100 py-1">
					<span class="pl-2">Marcas</span>
					<v-icon
						style="position: absolute; right: 5px"
						color="link"
						class="pointer"
						@click="brandsDialog = false"
						>mdi-close</v-icon
					>
				</div>
				<div class="px-2 pb-2" style="position: sticky; top: 0">
					<TextField
						:color="$vars.linktext"
						class="text-field"
						ref="searchfield"
						outlined
						clearable
						v-model="search"
						label="Filtrar..."
					/>
				</div>
				<v-list v-if="brands && brands.length" class="base">
					<v-virtual-scroll :items="filteredBrands" :height="height" item-height="49">
						<template #default="{ item }">
							<v-list-item
								class="base w100"
								@click="$router.replace($shop.getShopRoute({ brand: item.urlName }))"
							>
								<v-list-item-title :color="$vars.basetext" class="base font-1">{{
									item.name
								}}</v-list-item-title>
							</v-list-item>
						</template>
					</v-virtual-scroll>
				</v-list>
				<div v-else class="d-flex justify-center align-center pa-8">
					<v-progress-circular :size="50" indeterminate :color="$vars.primary"></v-progress-circular>
				</div>
			</div>
		</css-vars>
	</v-dialog>
</template>

<style scoped lang="scss">
::v-deep .v-text-field--outlined fieldset {
	border-color: var(--basetext);
}
::v-deep .v-text-field__slot {
	label {
		color: var(--basetext);
	}
}
::v-deep .theme--light.v-input input,
.theme--light.v-input textarea {
	color: var(--basetext);
}
.v-dialog__content ::v-deep .v-dialog {
	width: 100% !important;
	max-width: 600px;
	width: auto;
}
.dialog-items-border {
	border-bottom: 2px solid whitesmoke;
}

::v-deep .v-list-group__header,
::v-deep .v-list-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
