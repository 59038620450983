<script>
import { sync } from 'vuex-pathify'

export default {
	props: {
		hideIcons: Boolean,
		noBgItem: Boolean,
		dense: Boolean,
		btnClass: [String, Object],
		btnStyle: Object,
		activatorArrowColor: String,
	},
	data() {
		return {
			menuItems: this.$srv('menuLinks'),
			show: this.$srv('AppBarConfig.showLinks'),
			showBrands: false,
			brands: null,
			brandSearch: '',
		}
	},
	computed: {
		brandsDialog: sync('shop/brandsDialog'),
		filteredBrands() {
			let brands = this.brands
			if (this.brandSearch) {
				brands = brands.filter((x) => x.name.toLowerCase().includes(this.brandSearch.toLowerCase()))
			}
			return brands
		},
		height() {
			if (this.$ssr) return
			return window.innerHeight - 64 - 150
		},
	},
	watch: {
		showBrands(val) {
			if (val && !this.brands?.length) {
				this.$shopApi.get({
					url: '/catalog/brands',
					query: { buyables: true },
					onSuccess: ({ data }) => {
						this.brands = data.brands
						this.brands.sort((a, b) => {
							const aName = a.name.toLowerCase()
							const bName = b.name.toLowerCase()
							if (aName < bName) return -1
							if (aName > bName) return 1
							return 0
						})
					},
				})
			} else {
				this.brandSearch = ''
			}
		},
	},
}
</script>

<template>
	<div v-if="show && menuItems.length">
		<div v-for="(item, index) of menuItems" :key="index">
			<v-menu
				v-if="!item.type || item.type === 'link'"
				bottom
				open-on-hover
				offset-y
				:disabled="!item.children"
				class="px-0"
				min-width="200px"
				attach=""
			>
				<v-list :color="$vars.primary">
					<v-list-item class="" v-for="subitem of item.children" :key="subitem.id">
						<SafeLink :to="subitem.link" class="w100">
							<v-list-item-title>
								<div>{{ subitem.name }}</div>
							</v-list-item-title>
						</SafeLink>
					</v-list-item>
				</v-list>
				<template #activator="{ on, attrs }">
					<SafeLink :to="item.link" class="d-inline-block h100 d-flex">
						<v-btn
							v-bind="attrs"
							v-on="on"
							class="h100 primary"
							:style="[
								{ background: noBgItem && 'transparent!important' },
								{ padding: dense && '0 3px' },
								btnStyle,
							]"
							text
							depressed
							small
						>
							<v-icon v-if="!hideIcons && item.icon" :color="$vars.primarytext" class="mr-2">{{
								item.icon
							}}</v-icon>
							<span class="font-1 text-wrap-nowrap" :class="dense && 'text-transform-none'">
								{{ item.name }}
							</span>
							<span v-if="!!item.children">
								<v-icon :color="activatorArrowColor || $vars.primarytext"> mdi-chevron-down </v-icon>
							</span>
						</v-btn>
					</SafeLink>
				</template>
			</v-menu>
			<v-btn
				@click="brandsDialog = true"
				v-else-if="item.type && item.type === 'allBrands'"
				class="h100 primary appbar-menu-items"
				:style="[{ background: noBgItem && 'transparent!important' }, { padding: dense && '0 3px' }]"
				text
				height="100%"
				depressed
				small
			>
				<v-icon v-if="!hideIcons && item.icon" :color="$vars.primarytext" class="mr-2">{{
					item.icon
				}}</v-icon>
				<span class="font-1 text-wrap-nowrap" :class="dense && 'text-transform-none'">
					{{ item.name }}
				</span>
			</v-btn>
		</div>
	</div>
</template>

<style scoped lang="scss">
.text-wrap-nowrap {
	text-wrap: nowrap;
}
.text-transform-none {
	text-transform: none;
}
</style>
